//
// Pass some information as CSS variables to the print stylesheet
//
window.addEventListener('beforeprint', function() {
    const siteEnvironment = document.querySelector('#css-var-site-environment')
        ? document.querySelector('#css-var-site-environment').dataset.value
        : '';

    const pageTitle = document.querySelector('#css-var-page-title')
        ? document.querySelector('#css-var-page-title').dataset.value
        : '';

    document.documentElement.style.setProperty('--site-environment', `"${siteEnvironment}"`);
    document.documentElement.style.setProperty('--page-title', `"${pageTitle}"`);
});
