<template>
    <Modal v-if="open" @close="cancelAction">
        <template #header>
            <h5 class="modal-title">{{ title }}</h5>
        </template>

        <template #body>
            <slot></slot>
        </template>

        <template #footer>
            <div class="">
                <button type="button" class="btn-secondary mr-2" @click="cancelAction">
                    {{ cancelButtonText }}
                </button>
                <button type="button" :class="danger ? 'btn-danger' : 'btn'" @click="confirmAction" :disabled="submitting">
                    {{ confirmButtonText }}
                </button>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
    name: "ConfirmModal",

    components: {
        Modal,
    },

    props: {
        open: {
            type: Boolean,
            default: false,
        },
        submitting: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String,
            default: '',
        },
        confirmButtonText: {
            type: String,
            default: 'Confirm',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        danger: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['cancelled', 'confirmed'],

    methods: {
        cancelAction() {
            this.$emit('cancelled');
        },
        confirmAction() {
            this.$emit('confirmed');
        },
    },
}
</script>

<style scoped>

</style>
