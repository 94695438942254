<script>
import PrintIcon from "../../Icons/print.vue";

export default {
    name: "PrintButton",
    components: {PrintIcon},
    methods: {
        onClick() {
            window.print();
        }
    },
}
</script>

<template>

    <button type="button" class="btn js-print-button" @click="onClick">
        <PrintIcon class="mr-2" /> Print
    </button>
</template>

<style scoped>

</style>
