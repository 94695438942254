<script>
import CalendarIcon from "@/Icons/calendar.vue";
import Checkmark from "@/Icons/checkmark.vue";
import CloseIcon from "@/Icons/close.vue";
import axios from 'axios';
import FileUploadInput from "@/components/Form/FileUploadInput.vue";
import EntrantBlock from "@/components/Form/EntrantBlock.vue";
import modal from "@/components/Modal.vue";
import SignaturePad from "@/components/SignaturePad.vue";

export default {
    components: {
        CloseIcon,
        Checkmark,
        CalendarIcon,
        FileUploadInput,
        EntrantBlock,
        modal,
        SignaturePad,
    },
    data() {
        return {
            order: {
                id: '',
                field_contact_name: '',
                field_company_name: '',
                field_has_patent_application: 0,
                field_street: '',
                field_street_2: '',
                field_city: '',
                field_state: '',
                field_zipcode: '',
                field_country: '',
                field_phone: '',
                field_email: '',
                field_brief_explanation: '',
                field_date_of_application: '',
                field_patent_app_serial: '',
                field_patent_date_filed: '',
                field_patent_url: '',
                field_patent_number: '',
                field_patent_date_issued: '',
                field_is_recognized: 0,
                field_recognition_awards: '',
                field_other_joint_entities: '',
                field_unique_improvement: '',
                field_previous_current_tech: '',
                field_entry_advances_summary: '',
                field_industry_references: '',
                field_links: '',
                entrants: [],
                signature: '',
                stateProvinceList: this.states,
                disabledState: false,
            },
            config: {
                dateFormat: 'm-d-Y',
                minDate: "1.1.2023",
                maxDate: "12.31.2024",
                static: false,
            },
            showCancelModal: false,
            showSubmitModal: false,
            showSavingModal: false,
            showSignatureModal: false,
            errorObject: {},

            companyLogoFileCount: 0,
            assetFileCount: 0,
        }
    },
    props: {
        'message': {
            type: Object,
            default: null,
        },
        'order_source': {
            type: Object,
            default: null,
        },
        'company_logo_files': {
            type: Array,
            default: () => [],
        },
        'asset_files': {
            type: Array,
            default: () => [],
        },
        'sizzle_reel_files': {
            type: Array,
            default: () => [],
        },
        'order_id_source': {
            type: Number,
            default: null,
        },
        'auto_save': {
            type: Boolean,
            default: false,
        },
        'isAdmin': {
            type: Boolean,
            default: false,
        },
        isJudge: {
            type: Boolean,
            default: false,
        },
        'states': {
            type: Object,
            default: null,
            required: true,
        },
        'countries': {
            type: Object,
            default: null,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        'readonly_sizzle': {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        canUpdateSignature() {
            return !this.readonly;
        },
        signatureUrl() {
          // show either local unsaved signature or saved signature
          if (this.order.signature) {
              return this.order.signature;
          }

          return this.order_source ? this.order_source.signature_url : null;
        },
    },
    created() {
        if (this.order_source) {
            this.order = {
                ...this.order_source,
                // Create empty signature field so vue can track it for computed property.
                // This does not override anything, as it's a virtual field only for updating the signature.
                signature: '',
            };
        }

        // Set the state list to the country selected
        if(this.order && this.order.field_country == null) {
            this.order.field_country = 'US';
            this.countryUpdate();
        }
        if(this.order && this.order.field_country != '') {
            this.stateListModifier()
        }
    },
    methods: {
        toggleFunctionality: function (field, value, isBoolean, details = null) {
            if (isBoolean) {
                this.order[field] = value;
            } else {
                this.order[field] = details;
            }
        },
        updateEntrants: function (entrants) {
            this.order.entrants = entrants;
        },
        saveFormData: function () {
            axios.post('/submission/update',
                this.order,
            )
            .then(response => {
                if(!this.isAdmin) {
                    window.location.href = '/submissions';
                } else {
                    window.location.reload(true);
                }
            })
            .catch(error => {
                console.log(error);
            });

        },
        submitForm: function () {
            this.showSavingModal = true;
            axios.put('/submission/'+ this.order.id +'/submit',
                this.order,
            )
            .then(response => {
                this.showSavingModal = false;
                if(response.data.submitStatus == 'success') {
                    window.location.href = '/submissions';
                } else {
                    this.showSubmitModal = false;
                    this.errorObject = response.data.errors;
                    let errorMessage = 'Please fill in all the required fields before submitting.';
                    this.$root.$refs.flash_alert.activateFlashAlert(errorMessage, 'error');
                    this.$scrollTo('#top-of-form', 700);
                    this.activateEntrantWithErrors();
                }
            })
            .catch(error => {
                this.showSavingModal = false;
                console.log(error);
                if(error.errors) {
                    this.errorObject = error.errors;
                }
            });
        },
        submitCancel: function () {
            this.showCancelModal = false;

            axios.delete('/submission/'+ this.order.id +'/cancel')
                .then(response => {
                    window.location.href = '/submissions';
                }).catch(error => {
                    console.log(error);
                    if (error.response && error.response.data && error.response.data.message) {
                        this.$root.$refs.flash_alert.activateFlashAlert(error.response.data.message, 'error');
                        this.$scrollTo('#top-of-form', 700);
                    }
                });
        },
        countryUpdate() {
            this.stateListModifier();
            // this.updateData();
        },
        stateUpdate(ev) {
            this.order.field_state = ev.code;
            // this.updateData();
        },
        stateListModifier() {
            this.disabledState = false;
            if(this.order.field_country == 'US') {
                this.stateProvinceList = this.states.slice(0, 57);
            } else if (this.order.field_country == 'CA') {
                this.stateProvinceList = this.states.slice(57, 70);
            } else {
                this.stateProvinceList = [];
                this.disabledState = true;
                this.order.field_state = '';
            }
            // const activeStateListArray = this.stateProvinceList.map( val => { return val.code })
            // if(!this.stateProvinceList.includes(this.order.field_state)) {
            //     this.order.field_state = '';
            // }
        },
        updateStateForAutofill(ev) {
            const stateInput = ev.target.value;
            let result = this.stateProvinceList.filter(function(stateObject) {
                if(stateObject.code == stateInput) {
                    return stateObject.code
                }
                if(stateObject.label == stateInput) {
                    return stateObject.code
                }
            })
            if(result && result[0]) {
                this.order.field_state = result[0].code;
            }
        },
        checkRequiredFieldFromError(fieldName) {
            if (!this.errorObject || !this.errorObject[fieldName]) {
                return '';
            }
            if (fieldName === 'company_logo') {
                return this.companyLogoFileCount === 0 ? 'required' : '';
            }
            if (fieldName === 'assets') {
                return this.assetFileCount === 0 ? 'required' : '';
            }
            if ((this.order[fieldName] == '' && this.order[fieldName] !== 0) || this.order[fieldName] == null) {
                return 'required';
            }
        },

        signatureClicked() {
            if (!this.canUpdateSignature) {
                return;
            }

            this.showSignatureModal = true;
        },

        saveSignature(signature) {
            this.order.signature = signature;
            this.showSignatureModal = false;
        },

        activateEntrantWithErrors() {
            if (!this.errorObject) {
                return false;
            }
            const errorKeys = Object.keys(this.errorObject).sort();
            const entrantsPrefix = 'entrants.';
            const firstEntrantErrorKey = errorKeys.find(key => key.startsWith(entrantsPrefix));

            if (!firstEntrantErrorKey) {
                return;
            }

            const entrantKey = firstEntrantErrorKey.split('.')[1];
            const entrantIndex = parseInt(entrantKey);

            if (Number.isNaN(entrantIndex)) {
                return;
            }

            this.$refs.entrantBlock.entrantActive(entrantIndex);
        }
    }
}

</script>

<template>
    <div>
        <instructions :show-instructions-on-load="!isAdmin" v-if="!isJudge">
            <template v-slot:header>
                <h2>Instructions </h2>
            </template>
            <template v-slot:instruction-content>
                <slot name="entry-instructions">
                </slot>
            </template>
        </instructions>

        <form>
            <div v-if="order.judging_status === 'Winner'" class="d-print-none">
                <h3  class="order-header mt-5 mb-2">Sizzle Reel</h3>
                <div class="form-group-beside row">
                    <label for="company_logo" class="col-12 col-md-6">
                        Sizzle Reel


                        <p class="mt-2" v-if="!readonly_sizzle">
                            Please upload your Sizzle Reel by dragging and dropping it into the marked field to the right.
                            Once the file upload is finished, it will turn green and say “Upload complete”.
                            No further action is required to submit your Sizzle Reel.
                        </p>
                    </label>
                    <div class="col-12 col-md-6 input-wrapper">
                        <file-upload-input
                            :readonly="readonly_sizzle"
                            :endpoint="'/submission/'+order_id_source+'/sizzle_reels'"
                            :submission_id="order.id"
                            title="Sizzle Reel"
                            field_name="sizzle_reel"
                            :allow-multiple="true"
                            :initial-files="sizzle_reel_files"
                        ></file-upload-input>
                    </div>
                </div>
            </div>


            <h3 class="order-header mt-5">Entry Details</h3>

            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_engineering_development')">
                <label for="field_engineering_development" class="col-12 col-md-6">Engineering, Science, Or Technology Development To Be Considered: <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_engineering_development" id="field_engineering_development"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_engineering_development">
                </div>
            </div>

            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_contact_name')">
                <label for="field_contact_name" class="col-12 col-md-6">Name And Title Of Person To Be Contacted Regarding This Entry: <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_contact_name" id="field_contact_name"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_contact_name">
                </div>
            </div>

            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_company_name')">
                <label for="field_company_name" class="col-12 col-md-6">Company Name: <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_company_name" id="field_company_name"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_company_name">
                </div>
            </div>


            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_email')">
                <label for="field_email" class="col-12 col-md-6">
                    Email: <em class="yellow no-style">*</em>
                    <div class="description pl-4 mt-1" v-if="errorObject && errorObject['field_email'] && (order.field_email == '' || order.field_email == null)"><p class="red-font">{{ errorObject['field_email'][0] }}</p></div>
                </label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="email" v-model="order.field_email" id="field_email"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_email">
                </div>
            </div>
            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_phone')">
                <label for="field_phone" class="col-12 col-md-6">Phone Number: <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_phone" id="field_phone"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_phone">
                </div>
            </div>
            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_street')">
                <label for="field_street" class="col-12 col-md-6">Street Address: <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_street" id="field_street"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_street">
                </div>
            </div>
            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_street_1')">
                <label for="field_street_1" class="col-12 col-md-6">Street Address 2: </label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_street_1" id="field_street_1"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_street_1">
                </div>
            </div>
            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_city')">
                <label for="field_city" class="col-12 col-md-6">City: <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_city" id="field_city"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_city">
                </div>
            </div>

            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_state')">
                <label class="col-12 col-md-6">State</label>
                <div class="col-12 col-md-6 input-wrapper">
                    <v-select v-if="!readonly" @change="updateStateForAutofill($event)" :disabled="disabledState" class="no-deselect" placeholder="State" :reduce="label => label.code" v-model="order.field_state" :options="stateProvinceList" @option:selected="stateUpdate($event)"></v-select>
                    <span v-else>{{stateProvinceList.find(state => state.code === order.field_state)?.label}}</span>
                    <input v-model="order.field_state" type="hidden">
                </div>
            </div>

            <div class="form-group-beside row"  :class="checkRequiredFieldFromError('field_zipcode')">
                <label for="field_zipcode" class="col-12 col-md-6">Zip Code: <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_zipcode" id="field_zipcode"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_zipcode">
                </div>
            </div>

            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_country')">
                <label class="col-12 col-md-6">Country <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <v-select v-if="!readonly" class="no-deselect" placeholder="Country" :reduce="country => country.code" v-model="order.field_country" :options="Object.values(countries)" @option:selected="countryUpdate();"></v-select>
                    <span v-else>{{ countries[order.field_country]?.label }}</span>
                    <input v-model="order.field_country" type="hidden">
                </div>
            </div>



            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_brief_explanation')">
                <label for="field_brief_explanation" class="col-12 col-md-6">In brief, explain the technology or innovation and why it should be considered.<em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <textarea :readonly="readonly" v-model="order.field_brief_explanation" id="field_brief_explanation"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_brief_explanation d-print-none" rows="5"> </textarea>
                    <div class="d-none d-print-block textarea-content">{{ order.field_brief_explanation }}</div>
                </div>
            </div>

            <div class="form-calendar form-group-beside row" :class="checkRequiredFieldFromError('field_date_of_application')">
                <label for="field_date_of_application" class="col-12 col-md-6">
                    Date of invention or technology usage in television <em class="yellow no-style">*</em>
                </label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_date_of_application" id="field_date_of_application"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_date_of_application">
                </div>
            </div>

            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_has_patent_application')">
                <label for="field_has_patent_application" class="col-12 col-md-6">Has application been filed for patent? <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper" v-if="!readonly">
                    <div class="toggle-js">
                        <span class="yes-toggle" :class="order.field_has_patent_application==1?'active':''" @click="order.field_has_patent_application=1"> Yes</span>
                        <span class="no-toggle" :class="order.field_has_patent_application==0?'active':''" @click="order.field_has_patent_application=0"> No</span>
                    </div>
                    <input type="hidden" v-model="order.field_has_patent_application" id="field_has_patent_application" class="form-control field_has_patent_application">
                </div>
                <div class="col-12 col-md-6 input-wrapper" v-else>
                    <input readonly type="text" :value="order.field_has_patent_application==1 ? 'Yes' : 'No'">
                </div>
            </div>
            <div class="form-group-beside row" v-show="order.field_has_patent_application==1" :class="checkRequiredFieldFromError('field_patent_app_serial')">
                <label for="field_patent_app_serial" class="col-12 col-md-6">Serial number of application <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_patent_app_serial" id="field_patent_app_serial"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_patent_app_serial">
                </div>
            </div>
            <div class="form-group-beside row" v-show="order.field_has_patent_application==1" :class="checkRequiredFieldFromError('field_patent_date_filed')">
                <label for="field_patent_date_filed" class="col-12 col-md-6">Date of filing <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_patent_date_filed" id="field_patent_date_filed"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_patent_date_filed">
                </div>
            </div>
            <div class="form-group-beside row" v-show="order.field_has_patent_application==1" :class="checkRequiredFieldFromError('field_patent_url')">
                <label for="field_patent_url" class="col-12 col-md-6">If patent has been issued, URL where it can be accessed:</label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_patent_url" id="field_patent_url"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_patent_url">
                </div>
            </div>
            <div class="form-group-beside row" v-show="order.field_has_patent_application==1" :class="checkRequiredFieldFromError('field_patent_number')">
                <label for="field_patent_number" class="col-12 col-md-6">Patent Number:</label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_patent_number" id="field_patent_number"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_patent_number">
                </div>
            </div>
            <div class="form-group-beside row" v-show="order.field_has_patent_application==1" :class="checkRequiredFieldFromError('field_patent_date_issued')">
                <label for="field_patent_date_issued" class="col-12 col-md-6">Date issued:</label>
                <div class="col-12 col-md-6 input-wrapper">
                    <input :readonly="readonly" type="text" v-model="order.field_patent_date_issued" id="field_patent_date_issued"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_patent_date_issued">
                </div>
            </div>
            <h3 class="order-header mt-5 mb-2">Entrant Information</h3>

            <p>List the primary inventors/developers (not management or marketing) for this achievement:<br/>
                <span style="font-size:12px;">(Final eligibility is determined by the Engineering, Science & Technology Awards Committee)</span> </p>

            <entrant-block ref="entrantBlock" :readonly="readonly" :entry_entrants="order.entrants" :entryid="order.id" @update-entrants="updateEntrants" :error-object="errorObject"></entrant-block>

            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_is_recognized')">
                <label for="field_is_recognized" class="col-12 col-md-6">Has the achievement been recognized with a National Academy of Television Arts & Sciences Engineering Emmy or any other industry recognition? <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper" v-if="!readonly">
                    <div class="toggle-js">
                        <span class="yes-toggle" :class="order.field_is_recognized==1?'active':''" @click="order.field_is_recognized = 1"> Yes</span>
                        <span class="no-toggle" :class="order.field_is_recognized==0?'active':''" @click="order.field_is_recognized = 0"> No</span>
                    </div>
                    <input type="hidden" v-model="order.field_is_recognized" id="field_is_recognized" class="form-control field_is_recognized">
                </div>
                <div class="col-12 col-md-6 input-wrapper" v-else>
                    <input readonly type="text" :value="order.field_is_recognized==1 ? 'Yes' : 'No'">
                </div>
            </div>
            <div class="form-group-beside row" v-show="order.field_is_recognized==1" :class="checkRequiredFieldFromError('field_recognition_awards')">
                <label for="field_recognition_awards" class="col-12 col-md-6">If yes, list recognition(s) and year(s) awarded: <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <textarea :readonly="readonly" v-model="order.field_recognition_awards" id="field_recognition_awards"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_recognition_awards d-print-none" rows="5"> </textarea>
                    <div class="d-none d-print-block textarea-content">{{ order.field_recognition_awards }}</div>
                </div>
            </div>

            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_programs_list')">
                <label for="field_programs_list" class="col-12 col-md-6">Eligible entries must have been broadly adopted and utilized on a significant portion/volume/amount of nationally televised programming. Please list the program(s), broadcast date(s) and distribution platform(s). <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <textarea :readonly="readonly" v-model="order.field_programs_list" id="field_programs_list"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_programs_list d-print-none" rows="5"> </textarea>
                    <div class="d-none d-print-block textarea-content">{{ order.field_programs_list }}</div>
                    <p>List here or upload in the Supplementary Materials section below.</p>
                </div>
            </div>

            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_other_joint_entities')">
                <label for="field_other_joint_entities" class="col-12 col-md-6">Besides your company, please identify other companies that implement your solution and/or use this product to create television programming. <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <textarea :readonly="readonly" v-model="order.field_other_joint_entities" id="field_other_joint_entities"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_other_joint_entities d-print-none" rows="5"> </textarea>
                    <div class="d-none d-print-block textarea-content">{{ order.field_other_joint_entities }}</div>
                </div>
            </div>

            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_unique_improvement')">
                <label for="field_unique_improvement" class="col-12 col-md-6">In what way(s) is this item unique or an improvement over previous or current technologies? Please consider content quality, workflow, schedule, and cost. <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <textarea :readonly="readonly" v-model="order.field_unique_improvement" id="field_unique_improvement"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_unique_improvement d-print-none" rows="5"> </textarea>
                    <div class="d-none d-print-block textarea-content">{{ order.field_unique_improvement }}</div>
                </div>
            </div>
            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_previous_current_tech')">
                <label for="field_previous_current_tech" class="col-12 col-md-6">Please cite those previous or current technologies. <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <textarea :readonly="readonly" v-model="order.field_previous_current_tech" id="field_previous_current_tech"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_previous_current_tech d-print-none" rows="5"> </textarea>
                    <div class="d-none d-print-block textarea-content">{{ order.field_previous_current_tech }}</div>
                </div>
            </div>
            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_entry_advances_summary')">
                <label for="field_entry_advances_summary" class="col-12 col-md-6">In a brief summary, please explain how this technology or innovation enables or advances the art of the storytelling process. <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <textarea :readonly="readonly" v-model="order.field_entry_advances_summary" id="field_entry_advances_summary"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_entry_advances_summary d-print-none" rows="5"> </textarea>
                    <div class="d-none d-print-block textarea-content">{{ order.field_entry_advances_summary }}</div>
                </div>
            </div>
            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_industry_references')">
                <label for="field_industry_references" class="col-12 col-md-6">Please provide the name(s) and email contact information for industry professional(s) who can attest to how the entry advances the art of the storytelling process. <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <textarea :readonly="readonly" v-model="order.field_industry_references" id="field_industry_references"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_industry_references d-print-none" rows="5"> </textarea>
                    <div class="d-none d-print-block textarea-content">{{ order.field_industry_references }}</div>
                </div>
            </div>
            <div class="form-group-beside row" :class="checkRequiredFieldFromError('company_logo')" v-if="!isJudge">
                <label for="company_logo" class="col-12 col-md-6">Product Logo (must be a vector file - eps or ai) <em class="yellow no-style">*</em></label>
                <div class="col-12 col-md-6 input-wrapper">
                    <file-upload-input
                        :readonly="readonly"
                        :endpoint="'/submission/'+order_id_source+'/company_logo'"
                        :submission_id="order.id"
                        allowed-types="application/postscript, .eps, .ai"
                        title="Company Logo"
                        field_name="company_logo"
                        :allow-multiple="true"
                        :initial-files="company_logo_files"
                        @updateFileCount="companyLogoFileCount = $event"
                    ></file-upload-input>
                </div>
            </div>
            <h3  class="order-header mt-5 mb-2">Supplementary Materials</h3>
            <p>Add additional materials describing the submitted development such as links or videos below.</p>
            <div class="form-group-beside row" :class="checkRequiredFieldFromError('field_links')">
                <label for="field_links" class="col-12 col-md-6">Supplementary Links</label>
                <div class="col-12 col-md-6 input-wrapper">
                    <textarea :readonly="readonly" type="text" v-model="order.field_links" id="field_links"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control field_links d-print-none"></textarea>
                    <div class="d-none d-print-block textarea-content">{{ order.field_links }}</div>
                </div>
            </div>
            <div class="form-group-beside row" :class="checkRequiredFieldFromError('assets')">
                <label for="assets" class="col-12 col-md-6">Supplementary Files</label>
                <div class="col-12 col-md-6 input-wrapper">
                    <file-upload-input
                        :readonly="readonly"
                        :endpoint="'/submission/'+order_id_source+'/assets'"
                        :submission_id="order.id"
                        title="Assets"
                        field_name="assets"
                        :allow-multiple="true"
                        :initial-files="(asset_files)"
                        @updateFileCount="assetFileCount = $event"
                    ></file-upload-input>
                </div>
            </div>

            <section class="review-notes mt-5 mb-5">
                <h2>Acknowledgement and Entry Submission</h2>
                <p>
                    By signing this application, I agree that I am submitting this entry for consideration and by clicking submit, I agree that I am bound by all provisions of the rules and procedures for the Engineering, Science & Technology Emmy Awards including, without limitation, the rules that the Television Academy (a) may use collateral material submitted for all purposes, and (b) retains ownership of the Emmy statuette as provided in the "Protection of the Emmy Statuette" section of the rules.
                </p>

                <div class="signature-block" @click.prevent="signatureClicked()">
                    <div class="btn btn-primary btn-big" v-show="!signatureUrl" v-if="canUpdateSignature">
                        <circle_check class="svg-24"></circle_check> Click here to sign
                    </div>
                    <img :src="signatureUrl" />
                </div>
                <div class="red warning" v-show="!signatureUrl">Entry can only be submitted after all of the required information has been completed and the acknowledgement has been signed.</div>
            </section>

            <div class="d-flex justify-content-between align-items-center" v-if="!isAdmin && !readonly">
                <div>
                    <button @click.prevent="saveFormData" class="btn btn-primary">Save for later</button>
                    <button @click.prevent="showCancelModal = true" type="button" class="btn btn-delete ml-4" v-if="order.status === 'In Progress'">
                        Delete
                    </button>
                </div>
                <button @click.prevent="showSubmitModal = true" type="button" class="btn btn-primary btn-step-next" id="NextButton">
                    <div>
                        <span class="submit-text">Submit</span>
                    </div>
                    <right-small></right-small>
                </button>
            </div>
            <div class="d-flex justify-content-end align-items-center" v-else-if="isAdmin">
                <button @click.prevent="saveFormData" class="btn btn-primary">Save</button>
            </div>
        </form>

        <modal v-if="showSignatureModal" @close="showSignatureModal = false" modalclass="modal-signature">
            <h2 class="description" slot="header">Sign in the window below using your track pad or mouse</h2>
            <div class="content" slot="body">
                <SignaturePad @closeModalClicked="showSignatureModal = false" @signatureSave="saveSignature"></SignaturePad>
            </div>
            <div class="form-group-submit" slot="footer">
            </div>
        </modal>

        <modal v-if="showSubmitModal" @close="showSubmitModal = false">
            <h3 slot="header">Submit Entry</h3>
            <div class="content" slot="body">
                <p>Please click below to submit for approval. The Television Academy team will review your entry.</p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn-black" @click="showSubmitModal = false">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="submitForm()">Submit Entry</button>
            </div>
        </modal>

        <modal v-if="showSavingModal" @close="showSavingModal = false" class="savingmodal">
            <h1 slot="header" v-show="false"></h1>
            <div class="ballot-submitting-modal-content content" slot="body">
                <div class="spinner"></div>
            </div>
            <div class="form-group-submit" slot="footer" v-show="false"></div>
        </modal>

        <modal v-if="showCancelModal" @close="showCancelModal = false">
            <h3 slot="header">Delete Entry</h3>
            <div class="content" slot="body">
                <p>
                    Are you sure you want to delete this entry?
                </p>
            </div>
            <div class="form-group-submit" slot="footer">
                <button class="pull-left btn-black" @click="showCancelModal = false">Close</button>
                <button class="btn btn-primary pull-right" @click.prevent="submitCancel()">Delete Entry</button>
            </div>
        </modal>

    </div>
</template>

<style scoped>

</style>
