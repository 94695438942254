<script>
import Download from "../../Icons/download.vue";
import TrashIcon from "../../Icons/trash.vue";
import ConfirmModal from "../Common/ConfirmModal.vue";

import _ from "lodash";
import vueFilePond from 'vue-filepond/dist/vue-filepond.min.js';
import 'filepond/dist/filepond.min.css';

const FilePond = vueFilePond();

export default {
    name: "SubmissionJudgingFiles",

    components: {
        ConfirmModal,
        TrashIcon,
        Download,
        FilePond,
    },

    props: {
        uploadUrl: {
            type: String,
            required: true
        },
        listUrl: {
            type: String,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            files: [],
            loading: true,
            error: null,

            deleteModalOpen: false,
            deleteModalFile: null,
            deleteModalSubmitting: false,
            deleteModalError: null,

            // Using own minimal filepond instance instead of FileUploadInput
            // because we don't want filepond to handle deletes and list rendering here
            uploaderConfig: {
                url: this.uploadUrl,
                process: {
                    url: '',
                    onload: (response) => JSON.parse(response).fileId,
                },
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json',
                },
            },
            recentlyUploadedFiles: [],
        }
    },

    mounted() {
        this.loadFiles();
    },

    methods: {
        loadFiles() {
            this.error = null;

            axios.get(this.listUrl)
                .then(response => {
                    this.files = response.data.files;

                    this.removeRecentlyUploadedFiles();
                })
                .catch(error => {
                    this.error = 'Error loading files';
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        loadFilesAfterUpload: _.debounce(function () {
            this.loadFiles();
        }, 1000),

        openDeleteModal(file) {
            this.deleteModalFile = file;
            this.deleteModalOpen = true;
        },

        closeDeleteModal() {
            this.deleteModalOpen = false;
            this.deleteModalFile = null;
            this.deleteModalError = null;
        },

        confirmDelete() {
            this.deleteModalSubmitting = true;
            this.deleteModalError = null;

            axios.delete(this.deleteModalFile.delete_url)
                .then(response => {
                    this.loadFiles();
                    this.closeDeleteModal();
                })
                .catch(error => {
                    this.deleteModalError = error.response?.data?.message ?? 'Error deleting file';
                })
                .finally(() => {
                    this.deleteModalSubmitting = false;
                });
        },

        onProcessFile(error, file) {
            if (error) {
                return;
            }

            this.recentlyUploadedFiles.push(file.id);
            this.loadFilesAfterUpload();
        },

        removeRecentlyUploadedFiles() {
            this.recentlyUploadedFiles.forEach(file => {
                this.$refs.pond.removeFile(file);
            });
            this.recentlyUploadedFiles = [];
        },

    }
}
</script>

<template>
    <div>
        <div class="d-flex">
            <h2 class="flex-grow-1">Judging Files</h2>
        </div>
        <div class="mb-4 d-print-none" v-if="!readOnly">
            <h6>Upload New File</h6>
            <file-pond
                ref="pond"
                name="judge_file"
                allow-multiple
                item-insert-location="after"
                :server="uploaderConfig"
                :allow-revert="false"
                :allow-paste="false"
                :allow-remove="false"
                @processfile="onProcessFile"
            />
        </div>
        <div>
            <div v-if="error" class="error-text p-4">{{ error }}</div>
            <div class="d-flex flex-column align-items-center mb-3" v-if="loading">
                <div class="spinner small"></div>
                <span>Loading...</span>
            </div>

            <div class="text-center mb-3" v-if="!loading && files.length === 0">
                <p>No files uploaded yet.</p>
            </div>

            <div class="d-none d-print-block">
                <ul>
                    <li v-for="file in files" :key="file.id">
                        <strong>{{ file.original_file_name }}</strong> -
                        {{ file.created_at ? $formatDateTime(file.created_at) : '' }} -
                        {{ file.uploader_name }}
                    </li>
                </ul>
            </div>

            <div class="pt-2 d-print-none" v-if="!loading && !error">
                <ul class="list-group">
                    <li v-for="file in files" :key="file.id" class="list-group-item d-flex justify-content-between">
                        <div>
                            <a :href="file.url" class="file-name" target="_blank">{{ file.original_file_name }}</a>
                            <small class="d-block text-muted">
                                {{ file.created_at ? $formatDateTime(file.created_at) : '' }} <br />
                                {{ file.uploader_name }}
                            </small>
                        </div>
                        <div class="d-flex flex-column justify-content-between align-items-end d-print-none">
                            <div>
                                <a :href="file.url" target="_blank" :download="file.original_file_name">
                                    <Download />
                                </a>
                            </div>
                            <div>
                                <a href="#" role="button" class="delete-link" @click.prevent="openDeleteModal(file)" v-if="file.can_delete && !readOnly">
                                    <TrashIcon svg-class="svg-12 svg-accent" />
                                    Delete
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>


        <ConfirmModal
            :open="deleteModalOpen"
            :submitting="deleteModalSubmitting"
            title="Delete File"
            confirmButtonText="Delete"
            danger
            @cancelled="closeDeleteModal"
            @confirmed="confirmDelete"
        >
            <p>Are you sure you want to delete this file?</p>
            <p v-if="deleteModalFile">
                <strong>{{ deleteModalFile.original_file_name }}</strong>
            </p>
            <p class="error-text" v-if="deleteModalError">{{ deleteModalError }}</p>
        </ConfirmModal>
    </div>
</template>

<style scoped>
.file-name {
    word-break: break-word;
}
.delete-link {
    font-size: 0.75em;
    white-space: nowrap;
}

</style>
