<template>
    <div class="admin-prev-next-wrapper position-absolute d-flex align-items-center" v-if="order_id">
        <div class="mr-2">
            <a :href="`/search`" class="admin-prev-action-wrapper btn text-center d-flex justify-content-center">
                <div class="small upper mt-0 line-height-18">Return to Search</div>
            </a>
        </div>
        <div class="mr-2">
            <a v-if="prevUrl" :href="'/submission/'+prevUrl+'/edit/'" class="admin-prev-action-wrapper btn text-center d-flex justify-content-center">
                <caretleft class="margin-auto"></caretleft>
                <div class="small upper mt-1">Previous</div>
            </a>
        </div>
        <div class="">
            <a v-if="nextUrl" :href="'/submission/'+nextUrl+'/edit/'" class="admin-next-action-wrapper btn text-center d-flex justify-content-center">
                <div class="small upper mt-1">Next</div>
                <caretright class="margin-auto"></caretright>
            </a>
        </div>
    </div>
</template>

<script>
import caretleft from '../Icons/caret_left.vue';
import caretright from '../Icons/caret_right.vue';
import play from '../Icons/play.vue';

export default {
    name: "SubmissionFormNextPrevAdmin",
    components: {caretleft, caretright, play},
    props: ['order_id'],
    data() {
        return {
            pluck_ids: [],
            nextUrl: '',
            prevUrl: ''
        }
    },
    mounted() {
        if (localStorage.getItem('ENG-admin--next-prev')) {
            this.pluck_ids = localStorage.getItem('ENG-admin--next-prev');
            this.prevNextUrls();
        }
    },
    methods: {
        prevNextUrls: function () {
            let app = this;
            if (app.pluck_ids && app.pluck_ids.length > 0) {
                let indexList = app.pluck_ids.split(',');
                let currentOrderIndex = indexList.indexOf(app.order_id);
                let prev_index = Number(currentOrderIndex) - Number(1);
                let next_index = Number(currentOrderIndex) + Number(1);

                if (prev_index >= 0) {
                    app.prevUrl = indexList[prev_index];
                }

                if (next_index < indexList.length) {
                    app.nextUrl = indexList[next_index];
                }
            }
        }
    }
}
</script>
