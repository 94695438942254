<script>
import PlusCircleIcon from "../../Icons/plus-circle.vue";
import ConfirmModal from "../Common/ConfirmModal.vue";

export default {
    name: "SubmissionJudgingNoteItem",

    components: {
        ConfirmModal,
        PlusCircleIcon,
    },

    props: {
        note: {
            type: Object,
            required: true
        },
    },

    emits: [
        'comment-added',
        'comment-edited',
        'comment-deleted',
    ],

    data() {
        return {
            addCommentOpen: false,
            newComment: '',
            commentSubmitting: false,
            commentError: null,

            editing: false,
            editText: '',
            editSubmitting: false,
            editError: null,

            deleteModalOpen: false,
            deleteSubmitting: false,
            deleteError: null,
        }
    },

    mounted() {
    },

    methods: {
        addComment() {
            this.commentSubmitting = true;
            this.error = null;
            axios.post(this.note.comment_url, {content: this.newComment})
                .then(response => {
                    this.newComment = '';
                    this.addCommentOpen = false;
                    this.$emit('comment-added');
                })
                .catch(error => {
                    this.error = error.response.data.message ?? 'Error adding comment';
                })
                .finally(() => {
                    this.commentSubmitting = false;
                });
        },
        startEdit() {
            this.editing = true;
            this.editText = this.note.content;
        },
        submitEdit() {
            this.editError = null;
            axios.put(this.note.edit_url, {content: this.editText})
                .then(response => {
                    this.editing = false;
                    this.$emit('comment-edited');
                })
                .catch(error => {
                    this.editError = 'Error saving changes';
                });
        },


        startDelete() {
            this.deleteModalOpen = true;
        },
        closeDeleteModal() {
            this.deleteModalOpen = false;
            this.deleteError = null;
            this.deleteSubmitting = false;
        },
        confirmDelete() {
            this.deleteSubmitting = true;
            this.deleteError = null;
            axios.delete(this.note.delete_url)
                .then(() => {
                    this.$emit('comment-deleted');
                    this.closeDeleteModal();
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.deleteError = error.response.data.message;
                    } else {
                        this.deleteError = 'Error deleting comment';
                    }
                })
                .finally(() => {
                    this.deleteSubmitting = false;
                });
        },
    }
}
</script>

<template>
    <div class="note">
        <div class="note-meta">
            <span class="note-author">{{ note.author_name }}</span>,
            <span class="note-date">{{ $formatDateTime(note.created_at) }}</span>
            <span class="note-edited-hint" v-if="note.is_edited"> - last edited at {{ $formatDateTime(note.updated_at) }}</span>
        </div>
        <!-- For Print stylesheet, always show contents instead of edit form -->
        <div class="note-content d-none d-print-block">
            <!-- Messages stored as HTML are pre-sanitised. @see PHP helper sanitize_wysiwyg_html() -->
            <div v-if="note.as_html" v-html="note.content" class="org-content"></div>
            <div v-else v-text="note.content" class="org-content"></div>
        </div>
        <div class="note-content d-print-none" v-if="!editing">
            <!-- Messages stored as HTML are pre-sanitised. @see PHP helper sanitize_wysiwyg_html() -->
            <div v-if="note.as_html" v-html="note.content" class="org-content"></div>
            <div v-else v-text="note.content" class="org-content"></div>
        </div>
        <form class="d-print-none" @submit.prevent="submitEdit" v-else>
            <div class="form-group">
                <wysiwyg-input
                    name="note"
                    :model-value="editText"
                    @update-model-value="editText = $event"
                    include-link-option
                />
                <p class="error-text" v-if="editError">{{ editError }}</p>
            </div>
            <div>
                <button type="button" class="btn-secondary" @click="editing = false">Cancel</button>
                <button type="submit" class="btn-yellow  ml-2" :disabled="editSubmitting || !editText.replace('<p></p>', '').length">
                    <span v-if="editSubmitting">Submitting...</span>
                    <span v-else>Save Changes</span>
                </button>
            </div>
        </form>


        <div class="note-actions d-print-none">
            <a href="#" @click.prevent="startEdit" v-if="note.can_edit">Edit</a>
            <a href="#" @click.prevent="startDelete" v-if="note.can_delete">Delete</a>
            <a href="#" @click.prevent="addCommentOpen = true" v-if="note.can_comment">Add Comment</a>
        </div>

        <div v-if="addCommentOpen" class="comment-add-section d-print-none">
            <form class="form" @submit.prevent="addComment">
                <div class="mt-3">
                    <span>Write a comment:</span>
                    <wysiwyg-input
                        name="comment"
                        :model-value="newComment"
                        @update-model-value="newComment = $event"
                        include-link-option
                    />
                    <p class="error-text" v-if="commentError">{{ commentError }}</p>
                </div>
                <div class="mt-3">
                    <button type="button" class="btn-secondary" @click="addCommentOpen = false">Cancel</button>
                    <button type="submit" class="btn-yellow  ml-2" :disabled="commentSubmitting || !newComment.length">
                        <span v-if="commentSubmitting">Submitting...</span>
                        <span v-else>Add Comment</span>
                    </button>
                </div>
            </form>
        </div>

        <div class="comments">
            <SubmissionJudgingNoteItem
                v-for="comment in note.comments"
                :key="comment.id"
                :note="comment"
                @comment-added="$emit('comment-added')"
                @comment-edited="$emit('comment-edited')"
                @comment-deleted="$emit('comment-deleted')"
            />
        </div>

        <ConfirmModal
            :open="deleteModalOpen"
            :submitting="deleteSubmitting"
            title="Delete Comment"
            confirmButtonText="Delete"
            danger
            @cancelled="closeDeleteModal"
            @confirmed="confirmDelete"
        >
            <p>Are you sure you want to delete this comment?</p>
            <p class="error-text" v-if="deleteError">{{ deleteError }}</p>
        </ConfirmModal>
    </div>
</template>

<style scoped>

.comment-add-section {
    max-width: 600px;
}
.note {
    border: 1px solid #DCDCDC;
    padding: 1rem;
    margin-top: 1rem;
}
.note-meta {
    font-size: 0.9rem;
    color: #666;
}
.note-author {
    font-weight: bold;
}
.note-edited-hint {
    font-size: 0.8rem;
    color: #999;
}
.note-content {
    margin-top: 0.5rem;
    white-space: pre-wrap;
}
.note-actions {
    margin-top: 0.5rem;
}
.note-actions a {
    font-size: 0.9rem;
    margin-right: .8rem;
}

</style>
