<template>

    <svg    :class="svgClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M40 14h-4V6H12v8H8c-2.21 0-4 1.79-4 4v10c2.21 0 4-1.79 4-4v-6h32v12h-2v4h6V18c0-2.21-1.79-4-4-4zm-24 0v-4h16v4H16zm-4 16H4v4h8v8h24V22H12v8zm4-4h16v12H16V26zm12 8h-8v-4h8v4z" id="Layer_1" /></svg>
</template>

<script>
export default {
    name: 'printIcon',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>
