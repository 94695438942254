<template>
    <div class="entrant-wrapper-block">
        <div class="row width-100 no-gutters d-print-none">

            <div class="left entrant-left entrant-listing col-12 col-md-4">
                <ul>
                    <draggable v-if="entrants" class="drag-episode-titles" v-model="entrants" @start="drag=true" @end="drag=false, updateWeights()" filter=".readonly">

                        <li class="entrant-item" :class="leftSidebarClass(index, entrant)" v-for="(entrant, index) in entrants" @click="entrantActive(index)">
                            <div class="action-icons pull-right hide-me" v-if="!readonly">
                                <div class="d-inline-block align-middle move pl-3 pr-2"><span class="actions">Reorder<br/>Entrants</span><i class="fa fa-arrows-v"></i></div>
                                <div class="d-inline-block align-middle" v-if="entrants.length > 1" @click.prevent="activateDeleteEntrantPopup(entrant, index)"><trash class=" delete-entrant svg-18"></trash></div>
                            </div>
                            <div class="text" v-if="entrant.first_name">{{index+1}} - {{entrant.first_name}} {{ entrant.last_name }}
                            </div>
                            <div class="text" v-else>{{index+1}} - </div>
                        </li>
                    </draggable>
                </ul>
                <a href="#" @click.prevent="addNewEntrant()" id="createNewEntrantFromLeft" class="mt-2 btn btn-primary w-100" tabindex="-1" v-if="!readonly">
                    <plus_circle class="svg-24 svg-black"></plus_circle>
                    <span>Add another entrant</span>
                </a>
            </div>

            <div v-if="entrants" class="right omega entrant-right entrant-entry-right-form col-12 col-md-8 pl-3 " :class="checkAllRequiredMissing()">
                <div v-for="(entrant, index) in entrants">
                    <div class="vid-wrapper" v-if="index === activeEntrantIndex">
                        <div class="form-group-beside individual-field-value row no-gutters" :class="checkRequiredFieldFromError('entrants.'+index+'.first_name', entrant.first_name)">
                            <label :for="'first_name' + index" class="col-6 pl-2">First Name: <em class="yellow no-style">*</em></label>
                            <input :readonly="readonly" @blur="updateWeights()" type="text" v-model="entrant.first_name" required :name="'entrants.'+index+'.first_name'" :id="'first_name' + index"  class="first_name form-control col-6" maxlength="255">
                        </div>
                        <div class="form-group-beside individual-field-value row no-gutters" :class="checkRequiredFieldFromError('entrants.'+index+'.last_name', entrant.last_name)">
                            <label :for="'last_name' + index" class="col-6 pl-2">Last Name: <em class="yellow no-style">*</em></label>
                            <input :readonly="readonly" type="text" v-model="entrant.last_name" required :name="'last_name' + index" :id="'last_name' + index"  class="last_name form-control col-6" maxlength="255">
                        </div>
                        <div class="form-group-beside individual-field-value row no-gutters" :class="checkRequiredFieldFromError('entrants.'+index+'.email', entrant.email)">
                            <label :for="'email' + index" class="col-6 pl-2">Email: <em class="yellow no-style">*</em></label>
                            <input :readonly="readonly" type="email" v-model="entrant.email" :id="'email' + index" required :name="'email' + index"  class="email form-control col-6" maxlength="255">
                        </div>
                        <div class="form-group-beside individual-field-value row no-gutters" :class="checkRequiredFieldFromError('entrants.'+index+'.phone', entrant.phone)">
                            <label :for="'phone' + index" class="col-6 pl-2">Phone Number: <em class="yellow no-style">*</em></label>
                            <input :readonly="readonly" type="text" v-model="entrant.phone" :id="'phone' + index" required :name="'phone' + index"  class="last_name form-control col-6" maxlength="255">
                        </div>
                        <div class="form-group-beside individual-field-value row no-gutters" :class="checkRequiredFieldFromError('entrants.'+index+'.company', entrant.company)">
                            <label :for="'company' + index" class="col-6 pl-2">Company: <em class="yellow no-style">*</em></label>
                            <input :readonly="readonly" type="text" v-model="entrant.company" :id="'company' + index" required :name="'company' + index"  class="last_name form-control col-6" maxlength="255">
                        </div>
                        <div class="form-group-beside individual-field-value row no-gutters" :class="checkRequiredFieldFromError('entrants.'+index+'.title', entrant.title)">
                            <label :for="'title' + index" class="col-6 pl-2">Title: <em class="yellow no-style">*</em></label>
                            <input :readonly="readonly" type="text" v-model="entrant.title" :id="'title' + index" required :name="'title' + index"  class="last_name form-control col-6" maxlength="255">
                        </div>
                        <div class="form-group-beside individual-field-value row no-gutters" :class="checkRequiredFieldFromError('entrants.'+index+'.contribution', entrant.contribution)">
                            <label :for="'contribution' + index" class="col-6 pl-2">Contribution Description: <em class="yellow no-style">*</em></label>
                            <textarea :readonly="readonly" v-model="entrant.contribution" :id="'contribution' + index" required :name="'contribution' + index"  autocomplete="off" data-lpignore="true" data-form-type="other" class="form-control contribution" rows="5"> </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Print Version -->
        <div class="d-none d-print-block">
            <div v-for="(entrant, index) in entrants">
                <div class="vid-wrapper">
                    <div class="mt-4">
                        <h3 class="entrant-name">{{index+1}} - {{entrant.first_name}} {{ entrant.last_name }}</h3>
                    </div>
                    <div class="form-group-beside row no-gutters">
                        <label class="col-6 pl-2">First Name:</label>
                        <span class="col-6">{{ entrant.first_name }}</span>
                    </div>
                    <div class="form-group-beside row no-gutters">
                        <label class="col-6 pl-2">Last Name:</label>
                        <span class="col-6">{{ entrant.last_name }}</span>
                    </div>
                    <div class="form-group-beside row no-gutters">
                        <label class="col-6 pl-2">Email:</label>
                        <span class="col-6">{{ entrant.email }}</span>
                    </div>
                    <div class="form-group-beside row no-gutters">
                        <label class="col-6 pl-2">Phone Number:</label>
                        <span class="col-6">{{ entrant.phone }}</span>
                    </div>
                    <div class="form-group-beside row no-gutters">
                        <label class="col-6 pl-2">Company:</label>
                        <span class="col-6">{{ entrant.company }}</span>
                    </div>
                    <div class="form-group-beside row no-gutters">
                        <label class="col-6 pl-2">Title:</label>
                        <span class="col-6">{{ entrant.title }}</span>
                    </div>
                    <div class="form-group-beside row no-gutters">
                        <label class="col-6 pl-2">Contribution Description:</label>
                        <span class="col-6">{{ entrant.contribution }}</span>
                    </div>
                </div>
            </div>

            <hr class="mb-5 mt-5" />
        </div>

        <modal v-if="modalTriggerConfirmDelete" @close="modalTriggerConfirmDelete = false">
            <h3 slot="header">Removing Entrant File</h3>
            <div class="content" slot="body">
                <div v-if="entrantModalDelete.first_name">Are you sure you want to remove {{entrantModalDelete.first_name}} {{entrantModalDelete.last_name}} from this entry? </div>
                <div v-else>Are you sure you want to remove this entrant?</div>
            </div>
            <div class="form-group-submit clearfix" slot="footer">
                <button class="btn btn-cancel btn-transparent pull-left" @click.prevent="modalTriggerConfirmDelete = false">Cancel</button>
                <button class="pull-right" @click.prevent="deleteEntrant(entrantModalDelete.id)">Yes, Remove</button>
            </div>
        </modal>

    </div>

</template>

<script>
import Checkmark from "@/Icons/checkmark.vue";
import Modal from "@/components/Modal.vue";
import upload from '@/Icons/upload.vue';
import play from '@/Icons/play.vue';
import calendar from '@/Icons/calendar.vue';
import plus_circle from "@/Icons/plus-circle.vue";
import save from "@/Icons/save.vue";
import trash from "@/Icons/trash.vue";
import download from "@/Icons/download.vue";
import close from "@/Icons/close.vue";

export default {
    name: "EntrantBlock",
    components: {
        Checkmark, Modal, upload, play, calendar, save, plus_circle, trash, download, close
    },
    props: {
        entryid: {
            type: Number,
            required: true
        },
        entry_entrants: {
            type: Array,
            required: true
        },
        errorObject: {
            type: Object,
            required: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            entrants: [{
                id: null,
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                company: null,
                title: null,
                contribution: null,
                required: false,
            }],
            activeEntrantIndex: 0,
            entrantModalDelete: '',
            modalTriggerConfirmDelete: false,
        }
    },
    mounted() {
        if (this.entry_entrants) {
            this.entrants = this.entry_entrants;
        }
    },
    methods: {
        leftSidebarClass: function(index, entrant) {
            let app = this;
            let sidebarClass = '';
            if(index === app.activeEntrantIndex) {
                sidebarClass = 'active';
            }
            if(this.errorObject && (
                this.errorObject['entrants'] ||
                this.errorObject['entrants.'+index+'.first_name'] ||
                this.errorObject['entrants.'+index+'.last_name'] ||
                this.errorObject['entrants.'+index+'.email'] ||
                this.errorObject['entrants.'+index+'.phone'] ||
                this.errorObject['entrants.'+index+'.company'] ||
                this.errorObject['entrants.'+index+'.title'] ||
                this.errorObject['entrants.'+index+'.contribution']
            )) {
                sidebarClass += ' required';
            }

            if (this.readonly) {
                sidebarClass += ' readonly';
            }

            return sidebarClass;
        },
        entrantActive: function(index) {
            let app = this;
            app.activeEntrantIndex = index;
        },

        addNewEntrant: function() {
            let app = this;
            app.activeEntrantIndex = app.entrants ? app.entrants.length : 0;
            app.entrants.push({
                'id': null,
                'first_name': null,
                'last_name': null,
                'email': null,
                'phone': null,
                'company': null,
                'title': null,
                'contribution': null,
                'required': false,
            })
        },
        updateWeights: function() {
            let app = this;
            this.$emit('update-entrants', app.entrants);
        },
        activateDeleteEntrantPopup(entrant, index) {
            let app = this;
            app.modalTriggerConfirmDelete = true;
            app.entrantModalDelete = entrant;
            app.activeEntrantIndex = index;
        },
        deleteEntrant: function(entrantId) {
            let app = this;
            if(!entrantId) {
                app.entrants.splice(app.activeEntrantIndex, 1);
                app.modalTriggerConfirmDelete = false;
                if(app.activeEntrantIndex != 0) {
                    app.activeEntrantIndex = app.activeEntrantIndex-1;
                } else {
                    app.activeEntrantIndex = 0;
                }
                return;
            }

            let indexlist= [];
            app.entrants.splice(app.activeEntrantIndex, 1);
            app.entrants.forEach(function(entrant, index){
                indexlist[index] = entrant.id;
            });
            let actionUrl = `/entrant/detach`;
            window.axios(
                {
                    method: 'DELETE',
                    data: {
                        'entrant_id':entrantId,
                        'indexlist' : indexlist,
                    },
                    url: actionUrl,
                }).then(function(response) {
                app.modalTriggerConfirmDelete = false;
                app.activeEntrantIndex = 0;
                window.location.reload();
            }).catch(function (err) {
                let errorMessage = err.response.status + ' status error.';
            });
        },
        checkRequiredFieldFromError(fieldName, val) {
            if(this.errorObject && this.errorObject[fieldName]  && (val == undefined || val == null || val == '')) {
                return 'required';
            }
        },
        checkAllRequiredMissing() {
            if(this.errorObject && this.errorObject['entrants']) {
                if(this.entrants.every(entrant => {
                    return entrant.first_name == null && entrant.last_name == null && entrant.email == null && entrant.phone == null && entrant.company == null && entrant.title == null && entrant.contribution == null;
                })) {
                    return 'required';
                }
            }
        }
    }
}
</script>
