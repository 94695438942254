import './bootstrap';
import './print-support';

import Vue from 'vue';
import.meta.glob([
    '../images/**',
]);

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo)

// Draggable
import draggable from 'vuedraggable'
Vue.component('draggable', draggable);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
import "vue-select/dist/vue-select.css";

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
Vue.component('flatPickr', flatPickr);

import LaravelVuePagination from 'laravel-vue-pagination';
Vue.component('pagination', LaravelVuePagination);

import AdminSearch from "@/components/AdminSearch.vue";
import SubmissionForm from "@/components/SubmissionForm.vue";
import SubmissionFormNextPrevAdmin from "@/components/SubmissionFormNextPrevAdmin.vue";
import SubmissionStatuses from "@/components/SubmissionStatuses.vue";
import FlashAlert from "@/components/FlashAlert.vue";
import PhoneNumberInput from "./components/Form/PhoneNumberInput.vue";
import PasswordInput from "./components/Form/PasswordInput.vue";
import PrintButton from "./components/Common/PrintButton.vue";
import AdminChangeUser from "@/components/AdminChangeUser.vue";
import AdminInternalNotes from "@/components/AdminInternalNotes.vue";
import AdminOrderStatusUpdate from "@/components/AdminOrderStatusUpdate.vue";
import DataTable from "@/components/DataTable.vue";
import Modal from "@/components/Modal.vue";
import UsersTable from "@/components/UsersTable.vue";
import TabularReport from "@/components/TabularReport.vue";
import Instructions from "@/components/Instructions.vue";

import JudgeSearch from "@/components/JudgeSearch.vue";
import SubmissionJudgingNotes from "@/components/JudgingNotes/SubmissionJudgingNotes.vue";
import SubmissionJudgingFiles from "@/components/JudgingNotes/SubmissionJudgingFiles.vue";
import formattingPlugin from "./plugins/formattingPlugin.js";

import WysiwygInput from "@/components/Form/WysiwygInput.vue";

Vue.component('phone-number-input', PhoneNumberInput);
Vue.component('password-input', PasswordInput);
Vue.component('print-button', PrintButton);

Vue.component('admin-search', AdminSearch);
Vue.component('submission-form', SubmissionForm);
Vue.component('submission-form-next-prev-admin', SubmissionFormNextPrevAdmin);
Vue.component('submission-statuses', SubmissionStatuses);
Vue.component('flash-alert', FlashAlert);
Vue.component('admin-change-user', AdminChangeUser);
Vue.component('admin-internal-notes', AdminInternalNotes);
Vue.component('admin-order-status-update', AdminOrderStatusUpdate);
Vue.component('data-table', DataTable);
Vue.component('tabular-report', TabularReport);
Vue.component('modal', Modal);
Vue.component('users-table', UsersTable);
Vue.component('instructions', Instructions);

Vue.component('judge-search', JudgeSearch);
Vue.component('submission-judging-notes', SubmissionJudgingNotes);
Vue.component('submission-judging-files', SubmissionJudgingFiles);

Vue.component('wysiwyg-input', WysiwygInput);

Vue.use(formattingPlugin);

Vue.config.devtools = true;

const app = new Vue({
    el: '#app',
    data() {
        return {
            showLogout: false,
            date_config: {dateFormat: 'm-d-Y'},
            alertShow: false
        }
    },
    mounted() {
        var left      = document.getElementById("scrollTop"),
            stop      = (left && left.offsetTop) ? left.offsetTop - 120 : 0,
            docBody   = document.documentElement || document.body.parentNode || document.body,
            hasOffset = window.pageYOffset !== undefined,
            scrollTop;

        window.onscroll = function (e) {
            scrollTop = hasOffset ? window.pageYOffset : docBody.scrollTop;
            if (scrollTop >= stop && left) {
                if(scrollTop >= 121) {
                    left.style.top = '5px';
                } else {
                    left.style.top = 120 - scrollTop + 'px';
                }
            } else if(left) {
                left.style.top =  '121px';
            }
        }
    },
    methods: {
        showFlashAlert: function() {
            this.alertShow = true;
        },
    },
});
