<template>
    <div class="entry-info entryAdmin w-100">
        <div class="entry-info_item">
            <span class="label small-caps">Entry Status:</span>
            <span class="value">
                {{ order.status }}
            </span>
            <admin-order-status-update
                v-if="isAdmin"
                :action-url="status_update_url"
                :order-status="order.status"
                :statuses="submission_statuses"
                field-name="status"
            ></admin-order-status-update>
        </div>

        <div class="entry-info_item">
            <span class="label small-caps">Judging Status:</span>
            <span class="value" v-if="order.judging_status">
                {{ order.judging_status }}
            </span>
            <span v-else>-</span>
            <admin-order-status-update
                v-if="canUpdateJudgingStatus"
                :action-url="judgingStatusUpdateUrl"
                :order-status="order.judging_status"
                :statuses="judging_statuses"
                :include-blank="true"
                field-name="judging_status"
            ></admin-order-status-update>
        </div>

        <div class="entry-info_item">
            <span class="label small-caps">Eligibility Year:</span>
            <span class="value">{{ order.eligibility_year }}</span>
            <admin-order-status-update
                v-if="isAdmin"
                :action-url="status_update_url"
                :order-status="order.eligibility_year"
                :statuses="eligibility_years"
                field-name="eligibility_year"
            ></admin-order-status-update>
        </div>

        <div class="entry-info_item">
            <span class="label small-caps">Submitted Date:</span>
            <span class="value">{{ submitted_date_display ?? '' }}</span>
        </div>

        <admin-change-user
            v-if="isAdmin"
            :your_name="order.submitter_name ?? ''"
            :your_email="order.submitter.email ?? ''"
            :user_id="order.submitter_id ?? ''"
            :action_url="user_suggestions_url"
            :action_url_update="user_change_url"
            :order_id="order.order_id"
            :is_admin="role"></admin-change-user>

        <div class="entry-info_item" v-else>
            <span class="label small-caps">Submitter Name:</span>
            <span class="value">
                {{order.submitter_name ?? ''}} -
                <span class="email lowercase">{{order.submitter.email ?? ''}}</span>
            </span>
        </div>

        <div class="entry-info_item">
            <span class="label small-caps">Created Date:</span>
            <span class="value">{{ created_at_display ?? '' }}</span>
        </div>

        <div class="entry-info_item">
            <judge-update
                :is-admin="isAdmin"
                label="Assigned:"
                placeholder="Select Judges..."
                :judges="judges"
                :id="order.id"
                field-name="assigned_user_ids"
                :field-value="order.assigned_user_ids ?? []"
                :action-url="updateurl"
            ></judge-update>
        </div>


        <admin-internal-notes
            v-if="isAdmin"
            :action-url="updateurl"
            :internal-note-loaded="order.notes"
            :id="order.id">
        </admin-internal-notes>

        <div class="entry-info_item">
            <span class="label small-caps">Activity Log:</span>
            <span class="value"><a :href="activity_log_url">Open Activity Log</a></span>
        </div>

    </div>
</template>

<script>
    import PlusIcon from "@/Icons/plus.vue";
    import JudgeUpdate from "@/components/JudgeUpdate.vue";

    export default {
        components: {JudgeUpdate, PlusIcon},
        props: {
            isAdmin: Boolean,
            order: Array,
            judges: Array,
            status_update_url: String,
            updateurl: String,
            user_change_url: String,
            submission_statuses: Array,
            judging_statuses: Array,
            eligibility_years: Array,
            user_suggestions_url: String,
            judgingStatusUpdateUrl: String,
            canUpdateJudgingStatus: Boolean,
            activity_log_url: String,
        },
        created() {
            if(this.order.created_at) {
                // Set created_at to m-d-Y format
                let createdDate = new Date(this.order.created_at);
                this.created_at_display = createdDate.toLocaleString('en-us', { month: '2-digit' }) + '-' + createdDate.toLocaleString('en-us', { day: '2-digit' }) + '-' + createdDate.toLocaleString('en-us', { year: 'numeric' });
            }
            if(this.order.submitted_at) {
                // Set submitted_at to m-d-Y format
                let createdDate = new Date(this.order.submitted_at);
                this.submitted_date_display = createdDate.toLocaleString('en-us', { month: '2-digit' }) + '-' + createdDate.toLocaleString('en-us', { day: '2-digit' }) + '-' + createdDate.toLocaleString('en-us', { year: 'numeric' });
            }

        },
        data() {
            return {
                status_options: [{
                    text: "In Progress",
                    id: "In Progress"
                }, {
                    text: "Submitted",
                    id: "submitted"
                }, {
                    text: "Canceled",
                    id: "canceled"
                }, {
                    text: "Approved",
                    id: "approved"
                }, {
                    text: "Needs More Information",
                    id: "need_more_info"
                },{
                    text: "Ineligible",
                    id: "ineligible"
                }, {
                    text: "Live",
                    id: "live"
                }, {
                    text: "Live - Checked",
                    id: "live_checked"
                }],
                judging_status_options: [
                    { text: "In Progress", id: "in_progress" },
                    { text: "Assets Submitted", id: "submitted" },
                    { text: "Approved For Go Live", id: "approved" },
                    { text: "Need More Information", id: "need_more_info" },
                    { text: "Building Underway", id: "building" },
                    { text: "Build Complete", id: "build_complete" },
                    { text: "Published & Sent", id: "published" },
                    { text: "Canceled", id: "canceled" },
                ],
                created_at_display: '',
                submitted_date_display: '',
            }
        }
    }
</script>
