<template>
    <svg :class="svgClass" width="24px" height="24px" viewBox="0 0 24 24">
        <g id="1.-Foundations/Icons/ATAS_Icon_Arrow_Left_XSmall" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M13.12,14.71 L17.42,10.41 L16,9 L11.71,13.29 L7.42000001,9 L6,10.41 L10.3,14.71 C11.0800749,15.4854472 12.3399251,15.4854472 13.12,14.71 Z" id="Combined-Shape" fill="#000000" transform="translate(11.710000, 12.145793) rotate(90.000000) translate(-11.710000, -12.145793) "></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'caret_left',
    props: {
        svgClass: {
            type: String,
            default: 'svg-24'
        },
    }
}
</script>

